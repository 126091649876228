import React from 'react'

const CallAction = () => {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <span></span>
                            <h3>Permitenos cuidar de ti</h3> 
                            <h4>Horarios</h4>
                            <h5>24/7</h5>
                            <h5>365 Dias al año</h5>
                            <a className="rn-button-style--2" href="mailto:info@hospital-privado.com"><span>Contactanos</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallAction;