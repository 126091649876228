
let data = [
    {
        images: '01',
        title: 'Dr. Roberto Baldizon',
        designation: 'Director General',
        socialNetwork: [
        ]
    },
    {
        images: '02',
        title: 'Dr. Rodolfo Gonzalez',
        designation: 'Director Medico',
        socialNetwork: [
        ]
    },
    {
        images: '06',
        title: 'Dr. Xiomara Sanchez',
        designation: 'Medico Turnista',
        socialNetwork: [
        ]
    },
    {
        images: '06',
        title: 'Dr. Christa Barahona',
        designation: 'Medico Turnista',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Amaury de la Cruz',
        designation: 'Medico Turnista',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Gerardo Enamorado',
        designation: 'Medico Turnista',
        socialNetwork: [
        ]
    },
    {
        images: '04',
        title: 'Lic. Flor Fajaro',
        designation: 'Jefe de Enfermeria',
        socialNetwork: [
        ]
    },
    {
        images: '05',
        title: 'Lic. Oscar Ramirez',
        designation: 'Terapista Pulmonar',
        socialNetwork: [
        ]
    },
    {
        images: '06',
        title: 'Dr. Jennifer Perez',
        designation: 'Radiologa',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Kevin Flores',
        designation: 'Internista',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Jacinto Castellanos',
        designation: 'Traumatologo',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Cesar Ortiz',
        designation: 'Cirujano',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Flavio Axpuac',
        designation: 'Neurocirujano',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Herberth Melendez',
        designation: 'Internista',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Alejandro Saravia',
        designation: 'Radiologo',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Henry Miranda',
        designation: 'Ginecologo',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Edgar Lopez',
        designation: 'Internista',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Mariano Guerrero',
        designation: 'Gastroenterologo',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Gustavo Anleu',
        designation: 'Neumologo',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Dr. Emilio Mishaan',
        designation: 'Cirujano',
        socialNetwork: [
        ]
    }
];

export default data;