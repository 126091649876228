import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Helmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';


const SlideList = [
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--31',
        category: '',
        title: 'Hospital Privado de Peten',
        description: 'Velando por tu salud.',
        buttonText: 'Contactanos',
        buttonLink: 'mailto:info@hospital-privado.com'
    }
]

const starndardService = [
    {
        image: '',
        title: 'RESONANCIA',
        description: 'Examen de Resonancia Magnetica Nuclear con Equipo Siemens.',
    },
    {
        image: '',
        title: 'TOMOGRAFIA',
        description: 'Examen de Tomografia Helicoidal Computarizada con Equipo Siemens.',
    },
    {
        image: '',
        title: 'LABORATORIO',
        description: 'Examenes de Laboratorio Clinico con equipo nuevo y personal entrenado.',
    },
    {
        image: '',
        title: 'HOSPITALIZACION',
        description: 'Medicina General, por nuestros medicos en nuestras instalaciones',
    },
    {
        image: '',
        title: 'CONSULTA EXTERNA',
        description: 'Emergencia y Consulta de Caridad por nuestros Medicos.',
    },
    {
        image: '',
        title: 'ENDOSCOPIA',
        description: 'Examenes de Endoscopia y Colonoscopia por Especialistas con equipo nuevo.',
    },
    {
        image: '',
        title: 'NEUMOLOGIA',
        description: 'Jornadas de Consulta y Seguimiento de Neumologia por Especialista.',
    },
    {
        image: '',
        title: 'CIRUGIA',
        description: 'Cirugia por nuestros medicos y medicos especialistas visitantes en quirofano.',
    }
]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        return(
            <Fragment> 
                <Helmet pageTitle="HOSPITAL PRIVADO DE PETEN" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12">
                                <div className="about-inner inner">
                                        <h3>El Hospital Privado Universitario de Peten es el instituto de salud mas completo de su region, con Tomografia Helicoidal Computarizada Siemens, Resonancia Magnetica Nuclear Sisemens, Laboratorio Clinico Completo, EKG, EEG, Ultrasonido, Rayos X, Intensivo, Encamamiento y personal capacitado las 24 horas de los 7 dias de la semana.</h3>
                                        <h3>Ubicados en Santa Elena, Flores Peten, cerca de la nueva terminal de buses, el Hospital se encuentra accesible para la poblacion local, y su personal esta listo para atender toda emergencia medica con empatia y dedicacion. </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Counterup Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div>
                {/* End Counterup Area */}

                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">Servicios </h2>
                                    <br/>
                                </div>
                            </div>
                            {/* End Single Service  */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {starndardService.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="content">
                                                    <h3>{value.title}</h3>
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>                
                </div>
                {/* End Featured Service Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Equipo medico capacitado y disponible siempre</h2>
                                    <p>El Hospital Privado Universitario de Peten cuenta con medico las 24 horas los 7 dias de la semana listos para atender cualquier emergencia medica. Ademas de tener medico capacitado y disponible a toda hora, el hospital cuenta con especialistas y jornadas de medicos visitantes para atender todas las necesidades de la poblacion doliente.</p> 
                                    <p>Ademas del gran recurso medico, el hospital cuenta con enfermeras graduadas, enfermeras auxiliares y tecnicos con experiencia en el manejo de equipos radiologicos y de laboratorio bajo la supervision de profesionales certificados.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" teamStyle="" item="20" />
                        </div>
                        <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                            <br/>
                            <br/>
                            <h4>Hospital Privado Universitario de Peten</h4>
                            <h5>4a. Calle Zona 2 Santa Elena, Flores, Guatemala</h5>
                            <h5>+502 7926-1140</h5>
                            <h5>info@hospital-privado.com</h5>
                        </div>
                        {/* Start Single Widget  */}
                        <div className="col-lg-12 col-sm-12 col-12">
                            <br/>
                            <br/>
                            <WhatsAppWidget phoneNumber='+50279248440' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Hospital Privado Universitario de Peten' sendButton='Enviar'/>  
                        </div>
                         {/* End Single Widget  */}
                    </div>
                </div>
                {/* End Team Area  */}

             

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Acerca de Nosotros</h2>
                                        <p>En el Hospital Privado Universitario de Peten nuestra prioridad numero uno, al igual que nuestro compromiso con usted y su familia es su salud y bienestar.</p>  
                                        <p>Nuestros medicos velan por la salud de los pacientes que llegan al hospital, yendo la extra milla por diagnosticar y tratar correcta y profesionalmente cada caso.</p>
                                        <p>Con medico disponible las 24 horas, los 7 dias de la semana y equipo de la mas alta tecnologia, el hospital esta listo para poder atenderte en caso fuera necesario.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Brand Area  */}
                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                        <p>SEGUROS CON LOS QUE TRABAJAMOS:</p>
                        <br/>
                            <div className="col-lg-12">
                                <BrandOne branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;